<template>
    <div class="w-100">
        <div v-if="itemsCurrent.length" class="w-100 d-flex flex-wrap">
            <div
                v-for="(reference, index) in itemsCurrent"
                :key="reference.id"
                class="col-6 col-md-4 col-lg-3 col-xl-3 px-2 py-4 position-relative"
            >
                <img
                    v-img:group
                    loading="lazy"
                    :src="reference.images[0].url | image"
                    :alt="reference.images[0].alt"
                    class="rounded border border-primary w-100"
                />
                <div v-if="enabled" class="position-absolute top-0">
                    <vs-button danger icon @click="remove(index)">🗑</vs-button>
                </div>
                <div
                    class="bg-info d-flex align-items-center rounded max-w-24 pl-2 py-2"
                >
                    <div class="d-flex flex-wrap">
                        <div class="col-12 d-flex justify-content-between">
                            <div class="d-flex flex-column">
                                <small>
                                    Prod
                                    <router-link
                                        :to="`/app/products/detail/${reference.product.id}`"
                                        class="btn-a p-0"
                                    >
                                        {{
                                            reference.product.sku ||
                                            `ID ${reference.productId}`
                                        }}
                                    </router-link>
                                    | Ref
                                    <router-link
                                        :to="`/app/products/detail/${reference.product.id}?reference=${reference.id}&active=references`"
                                        class="btn-a p-0"
                                    >
                                        {{
                                            reference.barcode ||
                                            `ID ${reference.id}`
                                        }}
                                    </router-link>
                                </small>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-2 d-flex flex-column">
                            <strong class="">Color </strong>
                            <div class="d-flex align-items-center">
                                <small class="text-capitalize">
                                    {{ reference.color }}
                                </small>
                                <div
                                    class="ml-1 len-3 rounded-circle border border-secondary"
                                    :style="{
                                        'background-color': reference.colorRef
                                    }"
                                ></div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mt-2 d-flex flex-column">
                            <strong class="">Tamaño </strong>
                            <small class="text-capitalize">
                                {{ reference.size }}
                            </small>
                        </div>
                        <div class="col-12 mt-2 d-flex flex-column">
                            <strong>Precio actual </strong>
                            <div class="d-flex align-items-center">
                                <span class="">
                                    {{ reference.priceOffer | money }}
                                </span>
                                <small class="ml-2 text-through">
                                    {{ reference.priceOffer | money }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="col-10 mx-auto py-3">
            <span class="h4">{{ emptyMessage }}</span>
        </div>
    </div>
</template>

<script>
import { DEFAULT_IMAGE_URL } from "@/common/constants";

export default {
    name: "ReferencesCards",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            required: true,
            default: () => [
                {
                    id: 72,
                    size: "27-28",
                    color: "amarillo",
                    colorRef: "#f1c232",
                    product: {
                        name: "Botas para la lluvia de con sapito",
                        slug: "botas-para-la-lluvia-de-con-sapito",
                        brand: {
                            slug: "calzado-importado",
                            name: "Calzado Importado"
                        }
                    },
                    images: [
                        {
                            id: 128,
                            url: DEFAULT_IMAGE_URL,
                            alt: "Bota de niño de sapito",
                            order: {
                                order: 0
                            }
                        }
                    ]
                }
            ]
        },
        emptyMessage: {
            type: String,
            required: false,
            default: () =>
                "Nuestro sistema NO encuentra referencias asociadas 0️⃣."
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({}),
    methods: {
        remove(fromIndex) {
            let items = [...this.itemsCurrent];
            items.splice(fromIndex, 1);
            this.$emit("change", items);
        }
    }
};
</script>
